import clsx from 'clsx';
import React from 'react';

import type { ISelectInterval } from '../..';

type IntervalSelectorProps = {
  onSetInterval: (planInterval: ISelectInterval) => void;
  interval: ISelectInterval;
  isPricePage?: boolean;
  isLanding?: boolean;
};
export default function IntervalSelector({
  interval,
  isPricePage,
  isLanding,
  onSetInterval,
}: IntervalSelectorProps) {
  return (
    <div className="flex max-w-max items-center gap-2 self-center rounded-full border border-primary-light/25 p-2">
      <button
        onClick={() => onSetInterval('month')}
        className={clsx(
          'rounded-full px-3 py-1.5 transition-all duration-200',
          {
            'bg-gradient-to-r from-primary to-primary-light text-dark-light':
              interval === 'month',
            'text-dark-light': isPricePage,
            'text-dark-700 dark:text-dark-lighter': !isPricePage,
          }
        )}
      >
        Monthly
      </button>
      <button
        onClick={() => onSetInterval('year')}
        className={clsx(
          'rounded-full px-3 py-1.5 transition-all duration-200',
          {
            'bg-gradient-to-r from-primary to-primary-light text-dark-light':
              interval === 'year',
            'text-dark-light': isPricePage,
            'text-dark-700': isLanding,
            'text-dark-700 dark:text-dark-lighter': !isPricePage && !isLanding,
          }
        )}
      >
        Yearly
      </button>
    </div>
  );
}
