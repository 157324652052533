import { Checkbox } from '@canvaza/serval-ui';
import type {
  CheckboxProps,
  FieldWrapperPassThroughProps,
} from '@canvaza/serval-ui/dist/components/Form/types';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

const CheckboxInput = forwardRef<
  HTMLInputElement,
  CheckboxProps & FieldWrapperPassThroughProps
>(({ className, ...rest }, ref) => {
  return (
    <Checkbox
      {...rest}
      ref={ref}
      className={clsx(
        'border-[1.8px] border-primary-lighter/50 !bg-dark-lighter dark:!border-dark-200/70 dark:!bg-dark-600',
        className
      )}
    />
  );
});

CheckboxInput.displayName = 'CheckboxInput';
export default CheckboxInput;
