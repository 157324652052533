import { TextareaField } from '@canvaza/serval-ui';
import type { TextAreaFieldProps } from '@canvaza/serval-ui/dist/components/Form/types';
import clsx from 'clsx';
import React from 'react';

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  ({ error, success, ...rest }, ref) => {
    return (
      <TextareaField
        {...rest}
        error={error}
        success={success}
        ref={ref}
        className={clsx(
          'border-[1.2px] bg-transparent dark:bg-dark-400/50 dark:placeholder:text-dark-300/70',
          {
            'border-primary-lighter/50 dark:border-dark-300/70':
              !error || !success,
            'border-error-light/50': error,
            'border-success-light/50': success,
          }
        )}
      />
    );
  }
);

TextAreaInput.displayName = 'TextAreaInput';
export default TextAreaInput;
