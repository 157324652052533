import { Button, Modal } from '@canvaza/serval-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import CheckboxInput from '@/common/component/forms/CheckboxInput';
import SelectInput from '@/common/component/forms/SelectInput';
import TextAreaInput from '@/common/component/forms/TextAreaInput';
import TextInput from '@/common/component/forms/TextInput';
import ItemContainer from '@/common/widgets/containers/ItemContainer';
import type { IContactCreate } from '@/shared/firebase';
import { useCreateContact } from '@/shared/firebase/client/hooks';
import { useAuth } from '@/shared/hooks/useAuth';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import useTheme from '@/shared/hooks/useTheme';
import { CanvazaDarkIcon, CanvazaLightIcon } from '@/shared/utils/svg';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().required().label('Email'),
  phoneNo: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required()
    .label('Phone Number'),
  company: yup.string().required().label('Company'),
  companySize: yup.string().required().label('Company Size'),
  description: yup.string().label('Description'),
  agree: yup.bool().oneOf([true], 'Accept Ts & Cs is required'),
});

type NewUser = { firstName: string; lastName: string; email: string };
type ContactSalesTeamChildrenArgs = {
  openCustomRequestModal: () => void;
};
type ContactSalesTeamProps = {
  children: (args: ContactSalesTeamChildrenArgs) => ReactNode;
};

export default function ContactSalesTeamRender({
  children,
}: ContactSalesTeamProps) {
  const [modals, setModals] = useState({
    customRequest: false,
    customRequestSuccess: false,
  });
  const { user: authUser } = useAuth();
  const { isDark } = useTheme();
  const { currentUser, state: currentUserState } = useCurrentUser();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: authUser ? 'defaultFirstName' : undefined,
      lastName: authUser ? 'defaultLastName' : undefined,
      email: authUser ? 'defaultEmail' : undefined,
    },
    shouldFocusError: false,
  });

  const { create: createContact, state: createContactState } =
    useCreateContact();

  const onSubmit = (data: FieldValues) => {
    const newUser: NewUser = { email: '', firstName: '', lastName: '' };
    if (!currentUser) {
      newUser.firstName = data.firstName;
      newUser.lastName = data.lastName;
      newUser.email = data.email;
    } else {
      newUser.firstName = currentUser.firstName;
      newUser.lastName = currentUser.lastName;
      newUser.email = currentUser.email;
    }

    const newContact: IContactCreate = {
      ...newUser,
      phoneNo: data.phoneNo,
      company: data.company,
      companySize: data.companySize,
      description: data.description,
      agree: data.agree ?? false,
    };

    createContact(newContact);
  };

  const openCustomRequestModal = () => {
    setModals((prev) => ({ ...prev, customRequest: true }));
  };

  const closeCustomRequestModal = () => {
    setModals((prev) => ({ ...prev, customRequest: false }));
  };

  const openCustomRequestSuccessModal = () => {
    setModals((prev) => ({ ...prev, customRequestSuccess: true }));
  };

  const closeCustomRequestSuccessModal = () => {
    setModals((prev) => ({ ...prev, customRequestSuccess: false }));
  };

  useEffect(() => {
    if (createContactState.isSuccess) {
      reset();
      closeCustomRequestModal();
      openCustomRequestSuccessModal();
    }
  }, [createContactState.isSuccess]);

  return (
    <>
      {children({ openCustomRequestModal })}
      <Modal
        open={modals.customRequest}
        onClose={closeCustomRequestModal}
        maskClosable
        title="Contact our sales team"
        size="large"
        actions={<></>}
      >
        <div className="flex w-full gap-6 px-2 py-6">
          <ItemContainer className="hidden w-1/3 items-center justify-center md:flex">
            {isDark ? (
              <img
                src="/images/ContactFormCoverDark.png"
                alt="Contact form cover"
              />
            ) : (
              <img
                src="/images/ContactFormCoverLight.png"
                alt="Contact form cover"
              />
            )}
          </ItemContainer>
          <form
            className="flex w-full flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={clsx('flex items-center gap-3', {
                hidden: authUser,
              })}
            >
              <TextInput
                size="small"
                label="First Name"
                required
                fullWidth
                {...register('firstName')}
                error={!!errors.firstName?.message}
                description={errors.firstName?.message ?? ''}
              />

              <TextInput
                size="small"
                label="Last Name"
                required
                fullWidth
                {...register('lastName')}
                error={!!errors.lastName?.message}
                description={errors.lastName?.message ?? ''}
              />
            </div>
            <TextInput
              label="Company"
              required
              fullWidth
              size="small"
              {...register('company')}
              error={!!errors.company?.message}
              description={errors.company?.message ?? ''}
            />

            <SelectInput
              options={[
                { label: '1-15', value: '1-15' },
                { label: '16-100', value: '16-100' },
                { label: '101-500', value: '101-500' },
                { label: '501-1000', value: '501-1000' },
                { label: '1001+', value: '1001+' },
              ]}
              label="Company size"
              required
              fullWidth
              size="small"
              {...register('companySize')}
              error={!!errors.companySize?.message}
              description={errors.companySize?.message ?? ''}
            />
            <div className="flex items-end gap-3">
              <div
                className={clsx('w-full', {
                  hidden: authUser,
                })}
              >
                <TextInput
                  size="small"
                  label="Email"
                  required
                  fullWidth
                  {...register('email')}
                  error={!!errors.email?.message}
                  description={errors.email?.message ?? ''}
                />
              </div>
              <div className={clsx('w-full')}>
                <TextInput
                  size="small"
                  type="tel"
                  label="Phone number"
                  required
                  fullWidth
                  {...register('phoneNo')}
                  error={!!errors.phoneNo?.message}
                  description={errors.phoneNo?.message ?? ''}
                />
              </div>
            </div>

            <TextAreaInput
              label="Message"
              placeholder="Your message"
              fullWidth
              rows={5}
              {...register('description')}
            />
            <div className="flex justify-start gap-2">
              <div className="flex items-center gap-3">
                <div>
                  <CheckboxInput
                    {...register('agree', {})}
                    error={!!errors.agree?.message}
                    description={errors.agree?.message ?? ''}
                    id="agree"
                    label={
                      <p className="text-sm leading-7">
                        {'I agree to Canvaza’s'}
                        <a
                          href="/terms-and-conditions"
                          className="mx-2 text-primary dark:text-primary-light"
                        >
                          Terms and Conditions
                        </a>
                        Policy.
                      </p>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between gap-2 pt-4">
              <Button color="dark" onClick={closeCustomRequestModal}>
                No thanks
              </Button>
              <Button
                disabled={
                  createContactState.isLoading || currentUserState?.isLoading
                }
                isLoading={createContactState.isLoading}
                type="submit"
                size="large"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={modals.customRequestSuccess}
        onClose={closeCustomRequestSuccessModal}
        maskClosable
        title=""
        size="large"
        actions={<></>}
      >
        <div className="flex w-full gap-6 px-5 py-2">
          <ItemContainer className="hidden w-1/3 items-center justify-center md:flex">
            {isDark ? (
              <img
                src="/images/ContactFormSuccessDark.png"
                alt="Contact form success"
              />
            ) : (
              <img
                src="/images/ContactFormSuccessLight.png"
                alt="Contact form success"
              />
            )}
          </ItemContainer>
          <div className="flex w-full flex-col items-center justify-between gap-3">
            <div></div>
            <div className="flex flex-col items-center gap-7">
              <div className="flex flex-col items-center gap-1">
                <h1 className="text-2xl font-semibold">
                  We have received your information.
                </h1>
                <p>We will reach out to you within three business days.</p>
              </div>

              <span className="text-2xl font-semibold text-primary dark:text-primary-light">
                Thanks you!
              </span>
            </div>

            <div className="flex items-center gap-2">
              <span className="mt-0.5">
                {isDark ? (
                  <CanvazaLightIcon className="h-5" />
                ) : (
                  <CanvazaDarkIcon className="h-5" />
                )}
              </span>

              <span>TEAM</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
