import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';

import type { IPlanAttributes } from '@/shared/firebase/models/stripe-payment.interface';

import Attributes from './attributes';
import Action from './components/Action';
import PlanContainer from './components/PlanContainer';
import PlanDescription from './components/PlanDescription';
import PlanTitle from './components/PlanTitle';

type CustomPlanProps = {
  attributes: IPlanAttributes;
  isPricePage?: boolean;
  isLanding?: boolean;
  openContactModal?: () => void;
  isPriceOverviewPage?: boolean;
};
export default function CustomPlanPreview({
  attributes,
  isPricePage,
  isLanding,
  openContactModal,
  isPriceOverviewPage,
}: CustomPlanProps) {
  return (
    <PlanContainer isPricingPage={isPricePage} isLanding={isLanding}>
      <div className="flex w-full flex-col gap-3">
        <PlanTitle
          name="Enterprise"
          isPricePage={isPricePage}
          isLanding={isLanding}
        />
        <span
          className={clsx('text-3xl font-bold', {
            'text-dark-lighter': isPricePage,
            'text-dark-700': isLanding,
            'text-dark-darker dark:text-dark-light': !isPricePage && !isLanding,
          })}
        >
          Let&apos;s talk
        </span>

        {/* Description */}
        <PlanDescription
          description="Everything in canvaza"
          isPricePage={isPricePage}
          isLanding={isLanding}
        />

        {/* Action Buttons */}
        <Action type="custom" openContactSalesModal={openContactModal} />

        {/* Attributes */}
        <p
          className={clsx('font-medium', {
            'text-dark-lighter': isPricePage,
            'text-dark-700': isLanding,
            'text-dark-darker dark:text-dark-light': !isPricePage && !isLanding,
          })}
        >
          Features you will have
        </p>
        <Attributes
          attributes={attributes}
          isPricePage={isPricePage}
          isLanding={isLanding}
        />
      </div>
      {!isLanding && isPriceOverviewPage && (
        <div className="flex w-full items-center justify-center">
          <Button>Detail description</Button>
        </div>
      )}
    </PlanContainer>
  );
}
