import React from 'react';

export function PlanSkeleton() {
  return (
    <div className="flex h-[60vh] flex-col justify-between rounded-md border border-dark-200 p-7 dark:border-dark-700">
      <div className="flex items-center justify-between gap-3">
        <div className="h-5 w-1/2 rounded-lg bg-dark-200 p-1 dark:bg-dark-500"></div>
        <div className="h-5 w-full rounded-lg bg-dark-200 p-1 dark:bg-dark-500"></div>
      </div>
      <div className="py-10">
        <div className="h-6 w-1/3 rounded-lg bg-dark-200 p-1 dark:bg-dark-500"></div>
      </div>

      <div className="mb-12 flex flex-col gap-3 py-5">
        <div className="h-4 w-full rounded-lg bg-dark-200 p-1 dark:bg-dark-500"></div>
        <div className="h-4 w-full rounded-lg bg-dark-200 p-1 dark:bg-dark-500"></div>
        <div className="h-4 w-1/2 rounded-lg bg-dark-200 p-1 dark:bg-dark-500"></div>
      </div>
      <div className="mb-2">
        <div className="h-6 w-full rounded-lg bg-dark-200 p-1 md:w-1/2 dark:bg-dark-500"></div>
      </div>
    </div>
  );
}

export default function PlansSkeleton() {
  return (
    <div className="grid w-full grid-cols-1 gap-20 px-6 lg:grid-cols-3">
      {Array.from({ length: 3 }, (_, count) => (
        <PlanSkeleton key={count} />
      ))}
    </div>
  );
}
